@import url('https://fonts.googleapis.com/css?family=Roboto');
/* Global Styles */

:root {
    --primary-color: #003699;
    --dark-color: #0a0505;
    --light-color: #f4f4f4;
    --danger-color: #f00e0e;
    --success-color: #28a745;
    --kentai-color: #f00e0e;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    background-color: #fff;
    color: #333;
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

a:hover {
    color: #666;
}

ul {
    list-style: none;
}

img {
    width: 100%;
}

/* Utilities */

.container {
    max-width: 1700px;
    padding: 0 2rem;
}

/* Text Styles*/

.x-large {
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
}

.large {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 0.8rem;
}

.middle {
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 0.8rem;
}

.lead {
    font-size: 1rem;
    margin-bottom: 0.8rem;
}

.text-center {
    text-align: center;
}

.text-primary {
    color: var(--primary-color);
}

.text-light-color {
    color: var(--light-color);
}

.text-dark {
    color: var(--dark-color);
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-bold {
    font-weight: bolder;
}

.text-top-margin {
    margin-top: 0.5em;
}

.pdf-text {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 0.5em;
}

.pdf-text-list {
    margin-left: 7em;
}

/* Cards */

.card {
    padding: 1rem;
    border: #ccc 1px dotted;
    margin: 0.5rem;
}

/* Dropdown */

.dropdown-member {
    padding: 1rem;
    border: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

/* List */

.list {
    margin: 0.7rem 0;
}

.list li {
    padding-bottom: 0.3rem;
}

.list-block {
    display: block;
}

.list-button {
    float: right;
    margin-right: 0.2em;
}

/* Padding */

.p {
    padding: 0.5rem;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 2rem;
}

.p-3 {
    padding: 3rem;
}

.py {
    padding: 0.5rem 0;
}

.py-1 {
    padding: 1rem 0;
}

.py-2 {
    padding: 2rem 0;
}

.py-3 {
    padding: 3rem 0;
}

/* Margin */

.m {
    margin: 0.5rem;
}

.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 2rem;
}

.m-3 {
    margin: 3rem;
}

.my {
    margin: 0.5rem 0;
}

.my-1 {
    margin: 1rem 0;
}

.my-2 {
    margin: 2rem 0;
}

.my-3 {
    margin: 3rem 0;
}

.mt-2 {
    margin-top: 1.5em;
}

/* Grid */

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
}

.grid-2-3 {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 2rem;
}

.btn {
    display: inline-block;
    background: var(--light-color);
    color: #333;
    padding: 0.4rem 1.3rem;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: opacity 0.2s ease-in;
    outline: none;
}

.btn-link {
    background: none;
    padding: 0;
    margin: 0;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-sm {
    font-size: 0.8rem;
    padding: 0.3rem 1rem;
    margin-right: 0.2rem;
}

.badge {
    font-size: 0.8rem;
    padding: 0.2rem 0.7rem;
    text-align: center;
    margin: 0.3rem;
    background: var(--light-color);
    color: #333;
    border-radius: 5px;
}

.alert {
    padding: 0.7rem;
    margin: 1rem 0;
    opacity: 0.9;
    background: var(--light-color);
    color: #333;
}

.btn-kentai, .bg-kentai, .badge-kentai, .alert-kentai {
    background: var(--kentai-color);
    color: #f00e0e;
}

.btn-primary, .bg-primary, .badge-primary, .alert-primary {
    background: var(--primary-color);
    color: #fff;
}

.btn-light, .bg-light, .badge-light, .alert-light {
    background: var(--light-color);
    color: #333;
}

.btn-dark, .bg-dark, .badge-dark, .alert-dark {
    background: var(--dark-color);
    color: #fff;
}

.btn-danger, .bg-danger, .badge-danger, .alert-danger {
    background: var(--danger-color);
    color: #fff;
}

.btn-success, .bg-success, .badge-success, .alert-success {
    background: var(--success-color);
    color: #fff;
}

.btn-white, .bg-white, .badge-white, .alert-white {
    background: #fff;
    color: #333;
    border: #ccc solid 1px;
}

.btn:hover {
    opacity: 0.8;
}

.bg-light, .badge-light {
    border: #ccc solid 1px;
}

.round-img {
    border-radius: 50%;
}

/* Forms */

input {
    margin: 1.2rem 0;
}

.form-text {
    display: inline-block;
    margin-top: 0.3rem;
    color: #888;
}

input[type='text'], input[type='email'], input[type='password'], input[type='date'], select, textarea {
    display: block;
    width: 100%;
    padding: 0.4rem;
    font-size: 1.2rem;
    border: 1px solid #ccc;
}

.textfield {
    height: 15%;
}

input[type='submit'], button {
    font: inherit;
}

.form-group {
    margin: 1.2rem 0;
}

.form-group input {
    margin: 0.2rem 0;
}

.form-container {
    max-width: 500px;
    margin: 2rem auto;
    overflow: hidden;
    padding: 0 2rem;
}

.form-container h1, h2, h3 {
    text-align: center;
}

table th, table td {
    padding: 1rem;
    text-align: left;
}

table th {
    background: var(--light-color);
}

/* Navbar */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 1rem;
    z-index: 1;
    width: 100%;
    border-bottom: solid 1px var(--kentai-color);
    opacity: 0.9;
    margin-bottom: 1rem;
}

.navbar ul {
    display: flex;
}

.navbar a {
    color: #fff;
    padding: 0.45rem;
    margin: 0 0.25rem;
}

.navbar a:hover {
    color: var(--light-color);
}

.navbar .welcome span {
    margin-right: 0.6rem;
}

.kentai-logo {
    width: 0.9em;
    height: 0.8em;
}

/* Animation (Add After) */

.item-enter {
    opacity: 0;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 1000ms ease-in;
}

/* Calendar */

.calendar {
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #e4e7ea;
    background-color: var(--dark-color);
    color: var(--light-color);
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
    max-width: 350px;
    font-family: 'PT Sans', sans-serif;
}

.center-cal {
    margin-left: 45px;
}

.calendar-month_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.calendar-month_header_title {
    font-size: 20px;
}

.calendar-header_button.is-disabled {
    cursor: default;
}

.calendar-header_button {
    cursor: pointer;
    background: transparent;
    border-radius: 50%;
    border: 1px solid #e4e7ea;
    transition: border 0.1s ease-in, color 0.1s ease-in;
    position: relative;
    height: 35px;
    width: 35px;
}

button:disabled {
    background-color: var(--dark-color);
    color: var(--light-color);
    border-color: -internal-light-dark-color(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
}

button {
    -webkit-appearance: button;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark-color(buttontext, rgb(170, 170, 170));
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: -internal-light-dark-color(rgb(239, 239, 239), rgb(74, 74, 74));
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark-color(rgb(118, 118, 118), rgb(195, 195, 195));
    border-image: initial;
}

.calendar-header_button.is-prev:before {
    content: '<';
}

.calendar-header_button.is-next:after {
    color: var(--light-color);
    content: '>';
}

.calendar-header_button.is-next:before, .calendar-header_button.is-prev:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: fill 0.1s ease-in;
    color: var(--light-color);
}

.calendar-days_of_week {
    font-size: 13px;
    display: flex;
    color: #c3c3c3;
    text-align: center;
    margin-bottom: 25px;
}

.calendar-days_of_week_day {
    flex-basis: 15%;
}

.calendar-week {
    display: flex;
    margin-bottom: 8px;
}

.calendar-week-day.is-selectable {
    cursor: pointer;
    position: relative;
}

.calendar-week-day.is-prev_month, .calendar-week-day.is-next_month {
    color: var(--light-color);
}

.calendar-week-day:first-of-type {
    border-radius: 50% 0 0 50%;
}

.calendar-week-day {
    height: 34px;
    width: 34px;
    vertical-align: middle;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 15%;
    background-color: var(--dark-color);
    color: var(--light-color);
    position: relative;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    border: none;
    outline: 0;
}

.calendar-week-day.is-selectable:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}

/* Mobile Styles */

@media (max-width: 700px) {
    .hide-sm {
        display: none;
    }
    .grid-2, .grid-3, .grid-4 {
        grid-template-columns: 1fr;
    }
    /* Text Styles */
    .x-large {
        font-size: 3rem;
    }
    .large {
        font-size: 2rem;
    }
    .lead {
        font-size: 1rem;
    }
    /* Navbar */
    .navbar {
        display: block;
        text-align: center;
    }
    .navbar h1 {
        margin-bottom: 0.6rem;
    }
    .navbar ul {
        text-align: center;
        justify-content: center;
    }
    .center-cal {
        margin-left: unset;
    }
    .calendar {
        max-width: 265px;
    }
}

/* Desktrop view */

@media (min-width: 700px) and (max-width: 1200px) {
    .container {
        width: 100%;
    }
    .navbar {
        position: fixed;
    }
    .fixed {
        position: fixed;
    }
    .top {
        padding-top: 12rem;
    }
    .grid-2, .grid-3, .grid-4 {
        grid-template-columns: 1fr 1fr;
    }
    .card-grid-2 {
        grid-column: 2 / span 2;
        grid-row: 1 / span 1;
    }
    .card {
        flex-direction: column;
        display: flex;
        align-items: stretch;
        min-width: 320px;
        margin: 0.5rem;
        max-width: 31.3%;
    }
    .card-content {
        flex: 1 1 auto;
    }
}

/* Desktrop view */

@media (min-width: 1200px) {
    .container {
        width: 100%;
    }
    .navbar {
        position: fixed;
    }
    .fixed {
        position: fixed;
    }
    .top {
        padding-top: 8rem;
    }
    .card-grid-3 {
        display: grid;
        grid-column: 2 / span 2;
    }
    .card {
        min-width: 250px;
        margin: 0.5rem;
        max-width: 98%;
    }
    .list-card {
        float: left;
        width: 100%
    }
    .list-card li {
        float: left;
        margin-left: 1rem;
    }
    .list-card p {
        float: right;
    }
    .column {
        float: left;
        width: 90%;
    }
}